/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createSlice } from "@reduxjs/toolkit";

import { ApiCallState } from "common/utils/api-state";

import { ExternalExtImage, ExtImage } from "./media.entities";


export const MediaStore = createSlice({
  name: "media",
  initialState: {
    uploadExtImage: {} as ApiCallState<ExtImage>,
    updateExtImage: {} as ApiCallState<ExtImage>,
    generateProductImage: {} as ApiCallState<ExternalExtImage>,
  },
  reducers: {
    UploadExtImageState: (state, action) => {
      state.uploadExtImage = action.payload;
    },
    UpdateExtImageState: (state, action) => {
      state.uploadExtImage = action.payload;
    },
    GenerateProductImageState: (
      state,
      action: { payload: ApiCallState<ExternalExtImage> }
    ) => {
      state.generateProductImage = action.payload;
    }
  },
});

export const {
  UploadExtImageState,
  UpdateExtImageState,
  GenerateProductImageState,
} = MediaStore.actions;

export type MediaState = ReturnType<typeof MediaStore.getInitialState>

export const { reducer: mediaReducer } = MediaStore;