/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useMemo } from "react";

import {
	DropdownField, Form, NumericField, SelectField, SwitchField, TextAreaField,
	TextInputField
} from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import {
	useFieldName, useIsFieldHidden, useIsReadOnlyField
} from "common/utils";
import EmployeeCard from "employee/components/EmployeeCard";
import { Employee } from "employee/employee.entities";
import { useSyncEmployees } from "employee/employee.hooks";
import ExtImageField from "media/components/ExtImageField";
import { Table, tableStatuses } from "table/table.entities";

import styles from "./styles.module.scss";


export default function TableForm<T extends Table>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: TableFormProps<T>) {
  const classes = useMemo(() => [styles.tableForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);
  const isReadonly = useIsReadOnlyField(rest);

  const { employees } = useSyncEmployees({
    filter: (employee) => employee.role === 'employee',
  });

  return (
    <Form className={classes} {...rest}>
      <SwitchField
        name={fieldName("active")}
        label={["Active"]}
        value={value?.active}
        error={errors?.active}
        readOnly={readOnly}
        hidden={isHidden("active")}
        onChange={onChange}
      />
      <DropdownField
        name={fieldName("status")}
        label={["Status"]}
        value={value?.status}
        error={errors?.status}
        options={tableStatuses.map(status => ({
          id: status,
          value: status,
          name: _.startCase(status),
        }))}
        readOnly={readOnly}
        hidden={isHidden("status")}
        onChange={onChange}
      />
      <ExtImageField
        name={fieldName("picture")}
        label={["Picture"]}
        value={value?.picture}
        error={errors?.picture}
        readOnly={readOnly}
        hidden={isHidden("picture")}
        onChange={onChange}
        frameProps={{
          height: 150,
          width: 150,
        }} />
      <TextInputField
        maxLength={25}
        name={fieldName("name")}
        label={["Name (number)"]}
        value={value?.name}
        error={errors?.name}
        readOnly={readOnly}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <TextAreaField
        name={fieldName("description")}
        label="Description"
        value={value?.description}
        error={errors?.description}
        readOnly={readOnly}
        hidden={isHidden("description")}
        onChange={onChange} />
      <NumericField
        name={fieldName("seats")}
        label={["Seats"]}
        value={value?.seats}
        error={errors?.seats}
        readOnly={readOnly}
        hidden={isHidden("seats")}
        onChange={onChange}
      />
      <SelectField<Employee>
        name="waiter"
        label={'Waiter'}
        value={value?.waiter ? { id: value.waiter } : null}
        items={employees}
        onChange={(e) => {
          onChange({
            target: {
              name: e.target.name,
              value: e.target.value.id,
            }
          });
        }}
        hidden={isHidden("waiter")}
        buttonRenderer={(employee) => employee?.name || "Select an Employee"}
        itemPredicate={(query, employee) =>
          _.values(employee).join(' ').toLowerCase().includes(query.toLowerCase())
        }
        itemRenderer={(employee, props) =>
          <EmployeeCard
            hideRole
            key={employee.id}
            className={styles.selectEmployeeCard}
            value={employee}
            onClick={props.handleClick}
            onFocus={props.handleFocus}
          />
        } />
      {children}
    </Form>
  );
}

export type TableFormProps<T extends Table> = EntityFormProps<T> & {
};