/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ExtPhone } from "common/common.entities";
import { Employee } from "employee/employee.entities";
import { ExtImage } from "media/media.entities";


export const tableStatuses = [
  'free',
  'reserved',
  'taken',
] as const;

export interface Reservee {
  id: string;
  name: string;
  phone?: ExtPhone;
  email?: string;
}

export interface Reservation {
  reservedAt?: Date;
  reservedUntil?: Date;
  reservedBy: Reservee;
}

export interface Table {
  id: string;
  active: boolean;
  status: typeof tableStatuses[number];
  picture?: ExtImage;
  name: string;
  description?: string;
  seats: number;
  waiter?: string;
  reservation?: Reservation;
}