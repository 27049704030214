/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Icon, Spinner } from "@blueprintjs/core";
import { useEffect } from "react";
import { PiMagicWand } from "react-icons/pi";

import Button, { ButtonProps } from "common/components/buttons/Button";
import { empty, isSuccessful, useApiErrors, useApiState } from "common/utils";
import {
	GenerateProductImageAction
} from "media/actions/generate-product-image.action";
import { ExtImage } from "media/media.entities";
import { GenerateProductImageState } from "media/media.store";
import { Product } from "product/product.entities";

import styles from "./styles.module.scss";


export default function AiProductImageButton({
  product, onGenerated, intent, ...rest
}: ClipboardButtonProps) {
  const [generateState, dispatch] = useApiState((store) => store.media.generateProductImage);
  useApiErrors(generateState);

  useEffect(() => {
    if (isSuccessful(generateState)) {
      onGenerated(generateState.value!);
      dispatch(GenerateProductImageState(empty()));
    }
  }, [generateState]);

  return (
    <Button
      className={styles.aiProductImageButton}
      icon={
        generateState.isLoading ? (
          <Spinner
            className={styles.icon}
            size={12}
            intent={intent} />
        ) : (
          <Icon
            intent={intent}
            icon={
              <PiMagicWand
                className={styles.icon}
                size={12}
              />}
          />
        )
      }
      intent={intent}
      onClick={(e) => {
        if (!generateState.isLoading)
          dispatch(GenerateProductImageAction(product.name, product.description));
      }}
      {...rest}
    />
  );
}


export type ClipboardButtonProps = ButtonProps & {
  product: Product,
  onGenerated: (image: ExtImage) => void,
}