/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ExtractMenuState } from "catalog/catalog.store";
import { ApiAction, failed, loading, success } from "common/utils";


export function ExtractMenuAction(image: File): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(ExtractMenuState(loading()));
    try {
      const data = new FormData();
      data.append("image", image);
      const { value } = await api.post('/media/images/extract-menu', { data })
      dispatch(ExtractMenuState(success(value.entity)));
    } catch (error) {
      dispatch(ExtractMenuState(failed(error)));
    }
  };
}
