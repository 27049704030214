import axios, {
	AxiosError, AxiosInstance, AxiosRequestConfig, Method
} from "axios";
/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";
import moment from "moment";

import { Store } from "@reduxjs/toolkit";
import logger from "loglevel";
import { io, Socket } from "socket.io-client";

import { ApiState } from "app.store";
import { Identity } from "auth/auth.entities";
import { LoginState, Logout } from "auth/auth.store";

import { ApiError, isSuccessful, success } from "./api-state";


export const getApiUrl = () => process.env.REACT_APP_API_URL!;

class SocketIoWrapper {
  _socket?: Socket;
  _unsubsribeFromReconnectOnNewIdentity?: () => void;

  public disconnect() {
    this._unsubsribeFromReconnectOnNewIdentity?.call(this);
    this._socket?.disconnect();
  }

  public emit(event: string, params?: any) {
    this._socket?.emit(event, params);
  }
}

type Headers = { [header: string]: string };


export type ApiResponse<V = any> = {
  value?: V,
  error?: ApiError,
  identity?: Identity,
}

export type SocketIoCallback<P = any> = (payload: P) => void;

export interface SocketIoHandlers<P = any> {
  onEvent: {
    data: SocketIoCallback<P[]>,
    create: SocketIoCallback<P>,
    update: SocketIoCallback<P>,
    delete: SocketIoCallback<P>,
    [key: string]: (payload: P | any) => void,
  };
  onConnected?: (socket: Socket) => void;
  onDisconnected?: (reason?: string) => void;
  onError?: (error: any) => void;
}

export interface SocketIoOptions {
  reconnectOnNewIdentity: boolean, // default: true
  // disconnectOnNoIdentity: boolean, // default: true
}

export class ApiLoggingConfig {

  constructor(
    readonly logResponseData: boolean,
    readonly logRequestData: boolean,
    readonly logResponseHeaders: boolean,
    readonly logRequestHeaders: boolean,
    readonly logRequestParams: boolean,
  ) { }

  static logAll = new ApiLoggingConfig(true, true, true, true, true);
  static logNone = new ApiLoggingConfig(false, false, false, false, true);
  static logRequestOnly = new ApiLoggingConfig(true, true, false, false, true);
  static logResponseOnly = new ApiLoggingConfig(false, false, true, true, false);
  static muteRequestData = new ApiLoggingConfig(true, false, true, true, true);
  static muteResponseData = new ApiLoggingConfig(true, true, true, false, true);
}

export type RequestConfigs = AxiosRequestConfig & { logging?: ApiLoggingConfig };

export class ApiClient {
  private axiosClient: AxiosInstance;
  private refreshTokensPromise?: Promise<Identity>;
  private store?: Store<ApiState>;

  constructor() {
    this.axiosClient = axios.create();
    this.axiosClient.interceptors.request.use((config) => {
      config.baseURL = getApiUrl();
      return config;
    }, (error) => Promise.reject(error));
  }

  attachStore(store: Store<ApiState>) {
    this.store = store;
    this.axiosClient.interceptors.request.use((config) => {
      _.assign(config.headers, this.authHeaders());
      return config;
    }, (error) => Promise.reject(error));
  }

  private authHeaders(callerHeaders: Headers = {}): Headers {
    const headers: Headers = _.merge({}, callerHeaders);
    if (headers['Authorization'] == null && this.store) {
      const store = this.store.getState();
      if (store.auth.login?.value?.accessToken) {
        headers['Authorization'] = `Bearer ${store.auth.login!.value!.accessToken}`;
      }
    }
    return headers;
  }

  private async postResponse(res: ApiResponse): Promise<ApiResponse | null> {
    if (!this.store) return null;

    if (res.identity) {
      logger.debug('API returned new identity: ', res.identity);
      const state = this.store.getState();
      if (isSuccessful(state.auth.login)) {
        this.store.dispatch(LoginState(success(res.identity)));
      }
      return res;
    }
    if (res.error) {
      if (res.error.code === "relogin-required" ||
        res.error.code === "no-refresh-token") {
        logger.debug('relogin required!');
        this.store.dispatch(Logout());
        throw res.error;
      }
      if (res.error.code === "access-token-expired") {
        logger.debug('access token expired, refreshing...');
        const state = this.store.getState();
        if (isSuccessful(state.auth.login)) {
          await this.refreshTokens(state.auth.login!.value!.refreshToken);
          return null;
        }
      }
      throw res.error;
    }
    return res;
  }

  async refreshTokens(refreshToken) {
    if (!this.refreshTokensPromise) {
      this.refreshTokensPromise = (async () => {
        const res = await this.get("/business/auth/identity", {
          params: { refreshToken },
        });
        // waiting more requests to come and new identity to propogate
        await new Promise((resolve) => setTimeout(resolve, 1500));
        this.refreshTokensPromise = undefined;
        return res.identity!;
      })();
    }
    return this.refreshTokensPromise;
  }

  async get(path: string, config: RequestConfigs = {}) {
    return this.call(path, "get", config);
  }

  async post(path: string, config: RequestConfigs = {}) {
    return this.call(path, "post", config);
  }

  async patch(path: string, config: RequestConfigs = {}) {
    return this.call(path, "patch", config);
  }

  async put(path: string, config: RequestConfigs = {}) {
    return this.call(path, "put", config);
  }

  async delete(path: string, config: RequestConfigs = {}) {
    return this.call(path, "delete", config);
  }

  async call(path: string, method: Method, config: Omit<RequestConfigs, "method"> = {}): Promise<ApiResponse> {
    const loggingConfig = config?.logging || ApiLoggingConfig.logAll;
    logger.debug(
      `[ApiClient] ${method.toUpperCase()} ${path} ->` +
      (config.params == null ? '' : !loggingConfig.logRequestParams ?
        ` ${JSON.stringify(config.params)}` : ` <${config.params?.length} params>`) +
      (config.headers == null ? '' : !loggingConfig.logRequestHeaders ?
        ` ${JSON.stringify(config.headers)}` : ` <${config.headers?.length} headers>`) +
      (config.data == null ? '' : loggingConfig.logRequestData ?
        ` ${JSON.stringify(config.data)}` : ' <data>'));

    try {
      const ts = moment();
      const res = await this.axiosClient({ url: path, method, ...config });

      logger.debug(
        `[ApiClient] ${method.toUpperCase()} ${path} <-` +
        ` ${res.status} ${moment().diff(ts, 'ms')}ms` +
        (res.headers == null ? '' : loggingConfig.logResponseHeaders ?
          ` ${JSON.stringify(res.headers)}` : ` <${res.headers?.length} headers>`) +
        (res.data == null ? '' : loggingConfig.logResponseData ?
          ` ${JSON.stringify(res.data)}` : ` <data>`));

      return await this.postResponse(res.data) || await this.call(path, method, config);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if ((axiosError.response?.data as any).error) {
          await this.postResponse(axiosError.response!.data as any);
          return await this.call(path, method, config);
        }
      }
      throw error;
    }
  }

  sync<P = any>(path: string, handlers: SocketIoHandlers<P>, opts?: SocketIoOptions) {
    const ns = path.startsWith('/') ? path : '/' + path;
    const connect = (wrapper: SocketIoWrapper) => {
      wrapper._socket = io(getApiUrl() + ns, {
        autoConnect: false,
        auth: this.authHeaders(),
      });
      wrapper._socket.on("connect", () => {
        logger.debug(`io:${path} connected`);
        handlers.onConnected?.call(handlers, wrapper._socket!);
      });
      wrapper._socket.on("disconnect", (reason) => {
        logger.debug(`io:${path} disconnected: ${reason}`);
        handlers.onDisconnected?.call(handlers, reason);
      });
      wrapper._socket.on('reconnecting', (attempt) => {
        logger.debug(`io:${path} reconnecting (${attempt})`);
      });
      wrapper._socket.on('connect_error', (error) => {
        logger.warn(`io:${path} connect_error: ${error}`);
        handlers.onError?.call(handlers, error);
      });
      wrapper._socket.on('error_', (error) => {
        logger.error(`io:${path} error: `, error);
        this.postResponse({ error })
          .then(_res => {
            // wrapper._socket?.disconnect();
            // connect(wrapper);
          })
          .catch(error => handlers.onError?.call(handlers, error))
      });
      wrapper._socket.on('identity', (identity) => {
        this.postResponse({ identity });
      });
      _.forEach(_.entries(handlers.onEvent), ([eventType, handler]) => {
        wrapper._socket!.on(eventType, (data) => {
          logger.debug(`io:${path} ${eventType}: `, JSON.stringify(data));
          this.postResponse(data).then(handler as any);
        });
      });

      if (this.store && opts?.reconnectOnNewIdentity !== false) {
        const currentIdentity = this.store.getState().auth.login.value?.accessToken;
        wrapper._unsubsribeFromReconnectOnNewIdentity = this.store.subscribe(() => {
          if (currentIdentity != this.store!.getState().auth.login.value?.accessToken) {
            logger.debug(`identity has changed, reconnecting ${ns}`)
            wrapper.disconnect();
            connect(wrapper);
          }
        })
      }

      wrapper._socket!.connect();
    }
    const wrapper = new SocketIoWrapper();
    connect(wrapper);
    return wrapper;
  }
}
