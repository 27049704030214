/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import _ from "lodash";

import { Dialog, Icon } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaQrcode } from "react-icons/fa";
import QRCode from "react-qr-code";
import { saveSvgAsPng } from "save-svg-as-png";
import timezones from "timezones-list";

import { useIdentity } from "auth/auth.hooks";
import { Branch } from "branch/branch.entities";
import BranchCatalogForm from "branch/components/BranchCatalogForm";
import {
	Button, Callout, ClipboardButton, DropdownField, ExtPhoneField, Form,
	FormField, SwitchField, TextAreaField, TextInputField
} from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import ExtImageField from "media/components/ExtImageField";

import styles from "./styles.module.scss";


export default function BranchForm<T extends Branch>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: BranchFormProps<T>) {
  const rtl = document.body.classList.contains('rtl');
  const { t } = useTranslation();
  const identity = useIdentity();
  const classes = useMemo(() => [styles.branchForm, className].join(" "), [className]);

  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  const [qrCodeToDisplay, setQrCodeToDisplay] = useState('');
  const [isChangeSlugTooltipShown, showDisplayChangeSlugTooltip] = useState(false);

  return (
    <Form className={classes} {...rest}>
      {identity?.subscription != null ? null : (
        <Callout
          intent="warning"
          style={{ marginBottom: 15 }}
          hidden={isHidden("online")}>
          {["Branch.Form.onlineNoSubscriptionCallout"]}
        </Callout>
      )}
      <SwitchField
        name={fieldName("online")}
        label={["online"]}
        value={value?.online}
        error={errors?.online}
        readOnly={readOnly}
        hidden={isHidden("online")}
        onChange={onChange}
        tooltip={['Branch.Form.onlineTooltip']}
      />
      <ExtImageField
        name={fieldName("image")}
        label={["image"]}
        value={value?.image}
        error={errors?.image}
        readOnly={readOnly}
        hidden={isHidden("image")}
        onChange={onChange}
        frameProps={{
          height: 200,
          width: 300,
        }} />
      <TextInputField
        maxLength={25}
        name={fieldName("name")}
        label={["name"]}
        value={value?.name}
        error={errors?.name}
        readOnly={readOnly}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <TextAreaField
        maxLength={150}
        name={fieldName("description")}
        label={["description"]}
        value={value?.description}
        error={errors?.description}
        readOnly={readOnly}
        hidden={isHidden("description")}
        onChange={onChange} />
      <ExtPhoneField
        name={fieldName("phone")}
        label={["phone"]}
        value={value?.phone}
        error={errors?.phone}
        readOnly={readOnly}
        hidden={isHidden("phone")}
        onChange={onChange}
        tooltip={['Branch.Form.phoneTooltip']}
      />
      <ExtPhoneField
        name={fieldName("whatsapp")}
        label={["whatsApp"]}
        value={value?.whatsapp}
        error={errors?.whatsapp}
        readOnly={readOnly}
        hidden={isHidden("whatsapp")}
        onChange={onChange}
        tooltip={['Branch.Form.whatsAppTooltip']}
      />
      <TextInputField
        maxLength={50}
        name={fieldName("email")}
        label={["email"]}
        autoCapitalize='none'
        value={value?.email}
        error={errors?.email}
        readOnly={readOnly}
        hidden={isHidden("email")}
        onChange={onChange}
        tooltip={['Branch.Form.emailTooltip']}
      />
      <Tooltip2
        intent='danger'
        placement='top'
        isOpen={isChangeSlugTooltipShown}
        content={t("Branch.Form.changingSlugTooltip")}>
        <TextInputField
          maxLength={20}
          name={fieldName("slug")}
          label={["slug"]}
          autoCapitalize='none'
          value={value?.slug}
          error={errors?.slug}
          readOnly={readOnly}
          hidden={isHidden("slug")}
          onChange={onChange}
          onFocus={(e) => showDisplayChangeSlugTooltip(true)}
          onBlur={(e) => showDisplayChangeSlugTooltip(false)}
          tooltip={['Branch.Form.slugTooltip']}
          startElement={rtl ? undefined :
            <span className={styles.slugPrefix}>{process.env.REACT_APP_SLUG_PREFIX} </span>
          }
          endElement={
            <>
              <Button minimal
                icon={<FaQrcode />}
                hidden={_.isEmpty(value?.slug)}
                onClick={(e) => {
                  e.stopPropagation();
                  setQrCodeToDisplay(process.env.REACT_APP_SLUG_PREFIX! + value?.slug);
                }}
              />
              <ClipboardButton
                hidden={_.isEmpty(value?.slug)}
                textToCopy={process.env.REACT_APP_SLUG_PREFIX! + value?.slug}
              />
            </>
          }
        />
      </Tooltip2>
      <TextInputField
        maxLength={150}
        name={fieldName("keywords")}
        label={["keywords"]}
        autoCapitalize='none'
        value={value?.keywords}
        error={errors?.keywords}
        readOnly={readOnly}
        hidden={isHidden("keywords")}
        onChange={onChange}
        tooltip={['Branch.Form.keywordsTooltip']}
      />
      <FormField
        label={["catalogs"]}
        hidden={isHidden("catalogs")} >
        {(value?.catalogs || []).map((catalog, i) => (
          <BranchCatalogForm
            key={`catalog_${i}`}
            name={fieldName(`catalogs.${i}`)}
            value={catalog}
            errors={errors?.catalogs}
            readOnly={readOnly}
            // hidden={isHidden(`optionGroups.${i}`)}
            onChange={onChange}
          />
        ))}
      </FormField>
      <DropdownField
        nullable={value?.timezone == null}
        name="timezone"
        label={["timezone"]}
        value={value?.timezone}
        error={errors?.timezone}
        hidden={isHidden("timezone")}
        options={timezones.map(tz => ({
          id: tz.tzCode!,
          value: tz.tzCode,
          name: tz.label,
        }))}
        onChange={(event) => {
          onChange({
            target: {
              name: fieldName("timezone"),
              value: event.target.value,
            }
          })
        }}
      />
      <SwitchField
        name={fieldName("deliveryOptions.inStorePickup")}
        label={["Branch.Form.DeliveryOptions.inStorePickup"]}
        value={value?.deliveryOptions?.inStorePickup}
        error={errors?.deliveryOptions?.inStorePickup}
        readOnly={readOnly}
        hidden={isHidden("deliveryOptions.inStorePickup")}
        disabled={
          value?.deliveryOptions?.curbsidePickup !== true &&
          value?.deliveryOptions?.dineIn !== true
        }
        onChange={onChange}
        tooltip={['Branch.Form.DeliveryOptions.inStorePickupTooltip']}
      />
      <SwitchField
        name={fieldName("deliveryOptions.curbsidePickup")}
        label={["Branch.Form.DeliveryOptions.curbsidePickup"]}
        value={value?.deliveryOptions?.curbsidePickup}
        error={errors?.deliveryOptions?.curbsidePickup}
        readOnly={readOnly}
        hidden={isHidden("deliveryOptions.curbsidePickup")}
        disabled={
          value?.deliveryOptions?.inStorePickup !== true &&
          value?.deliveryOptions?.dineIn !== true
        }
        onChange={onChange}
        tooltip={['Branch.Form.DeliveryOptions.curbsidePickupTooltip']}
      />
      <SwitchField
        name={fieldName("deliveryOptions.dineIn")}
        label={["Branch.Form.DeliveryOptions.dineIn"]}
        value={value?.deliveryOptions?.dineIn}
        error={errors?.deliveryOptions?.dineIn}
        readOnly={readOnly}
        hidden={isHidden("deliveryOptions.dineIn")}
        disabled={
          value?.deliveryOptions?.inStorePickup !== true &&
          value?.deliveryOptions?.curbsidePickup !== true
        }
        onChange={onChange}
        tooltip={['Branch.Form.DeliveryOptions.dineInTooltip']}
      />
      {children}
      <Dialog
        className={styles.qrDialog}
        style={{ width: 350, padding: 20 }}
        isOpen={qrCodeToDisplay.length > 0}
        onClose={() => setQrCodeToDisplay('')} >
        <QRCode
          id='branch_qr_code'
          size={350}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={qrCodeToDisplay}
          viewBox={`0 0 350 350`}
        />
        <Icon
          className={styles.saveQrButton}
          size={40}
          icon="download"
          onClick={() => {
            setQrCodeToDisplay('');
            saveSvgAsPng(
              document.getElementById('branch_qr_code'),
              `${value!.name} QR.png`,
              { scale: 10 },
            );
          }}
        />
      </Dialog>
    </Form>
  );
}

export type BranchFormProps<T extends Branch> = EntityFormProps<T> & {
};