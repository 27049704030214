/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { CurrentBusinessChanged } from "business/business.store";
import { Catalog, ImportCatalog } from "catalog/catalog.entities";
import { ListOperation } from "common/utils";
import { ApiCallState, isSuccessful } from "common/utils/api-state";


export const CatalogStore = createSlice({
  name: "catalog",
  initialState: {
    sync: {} as ApiCallState<Catalog[]>,
    save: {} as ApiCallState<{ status: string, entity: Catalog }>,
    delete: {} as ApiCallState<Catalog>,
    extractMenu: {} as ApiCallState<ImportCatalog>,
    importMenu: {} as ApiCallState<ImportCatalog>,
  },
  reducers: {
    SyncCatalogsState: (
      state,
      action: { payload: ApiCallState<ListOperation<Catalog>> },
    ) => {
      state.sync = {
        ...state.sync,
        ...action.payload,
        value: action.payload.value?.apply(state.sync.value) || state.sync.value,
      }
    },
    SaveCatalogState: (
      state,
      action: { payload: ApiCallState<{ status: string, entity: Catalog }> },
    ) => {
      state.save = action.payload;
      if (isSuccessful(action.payload) && isSuccessful(state.sync)) {
        const idx = state.sync.value!.findIndex(item => item.id === action.payload.value!.entity.id);
        const newSyncValue = state.sync.value!.slice();
        if (idx === -1) {
          newSyncValue.push(action.payload.value!.entity);
        } else {
          newSyncValue[idx] = action.payload.value!.entity;
        }
        state.sync.value = newSyncValue;
      }
    },
    DeleteCatalogState: (
      state,
      action: { payload: ApiCallState<Catalog> },
    ) => {
      state.delete = action.payload;
      if (isSuccessful(action.payload) && isSuccessful(state.sync)) {
        const idx = state.sync.value!.findIndex(item => item.id === action.payload.value!.id);
        if (idx !== -1) {
          const newSyncValue = state.sync.value!.slice();
          newSyncValue.splice(idx, 1);
          state.sync.value = newSyncValue;
        }
      }
    },
    ExtractMenuState: (
      state,
      action: { payload: ApiCallState<ImportCatalog> }
    ) => {
      state.extractMenu = action.payload;
    },
    ImportMenuState: (
      state,
      action: { payload: ApiCallState<ImportCatalog> }
    ) => {
      state.importMenu = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, getInitialState);
    builder.addCase(CurrentBusinessChanged, getInitialState);
  },
});

export const {
  SyncCatalogsState,
  SaveCatalogState,
  DeleteCatalogState,
  ExtractMenuState,
  ImportMenuState,
} = CatalogStore.actions;

export const { reducer: catalogReducer, getInitialState } = CatalogStore;

export type CatalogState = ReturnType<typeof CatalogStore.getInitialState>
