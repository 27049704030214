/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ApiAction, failed, loading, success } from "common/utils";
import { ApiLoggingConfig } from "common/utils/api-client";

import { GenerateProductImageState } from "../media.store";


export function GenerateProductImageAction(name: string, description?: string): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(GenerateProductImageState(loading()));
    try {
      const { value } = await api.get('/media/images/generate/product', {
        params: { name, description },
        logging: ApiLoggingConfig.muteResponseData,
      })
      dispatch(GenerateProductImageState(success(value)));
    } catch (error) {
      dispatch(GenerateProductImageState(failed(error)));
    }
  };
}
